.close-button {
  overflow: hidden;
  position: absolute;
  text-align: center;
  user-select: none;
}

.close-button-icon {
  width: 100%;
  height: 100%;
}
