.ui {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}

@media (max-width: 700px) {
  .ui {
    max-width: 100% !important;
    width: 100% !important;
  }
  .open-share {
    font-size: max(3.55vh, 3.55vw) !important;
    padding-right: 6.6%;
    bottom: 0 !important;
    padding-bottom: 3.7vh !important;
    transform: translateY(0) !important;
  }
  .open-colophon {
    padding-left: 7.5%;
    left: 0 !important;
    bottom: 0 !important;
    width: max(7vh, 7vw) !important;
    padding-bottom: 2.7vh;
  }

  .plus-button {
    width: calc(max(10.5vh, 8.5vw) * 3.92) !important;
  }
  .plus-button-click-area {
    width: calc(max(10.5vh, 8.5vw)) !important;
    height: calc(max(10.5vh, 8.5vw)) !important;
  }

  .release {
    font-size: max(3.25vh, 3.25vw) !important;
  }
}

@media (min-width: 701px) {
  .ui {
    width: 100%;
    max-width: max(630px, calc(0.21 * 1.777 * 100vh)) !important;
  }
  .open-share {
    font-size: 27px !important;
    bottom: 22% !important;
    padding-right: 0 !important;
    transform: translateY(-15%) !important;
  }
  .open-colophon {
    bottom: 22% !important;
    left: 2% !important;
    width: 54px !important;
  }

  .release {
    font-size: 20px !important;
  }

  .plus-button {
    width: calc(85px * 3.92);
  }

  .plus-button-click-area {
    width: calc(85px);
    height: calc(85px);
  }
}

.release {
  position: absolute;
  left: 50%;
  bottom: 50%;
  width: 80%;
  transform: translate(-50%, 50%);
  /* overflow: hidden; */
  color: white;
  opacity: 0.8;
  text-align: center;
  user-select: none;
}

.plus-button-click-area {
  position: absolute;
  transform: translate(-50%, 50%);
  left: 50%;
  bottom: 50%;
  user-select: none;
  pointer-events: all;
}

.plus-button {
  left: 50%;
  bottom: 50%;
  position: absolute;
  transform: translate(-50%, 50%);
  user-select: none;
  pointer-events: none;
}

.open-share {
  position: absolute;
  user-select: none;
  color: white;
  text-align: right;
  letter-spacing: 0.038em;
  opacity: 0.8;
  right: 0;
  /* left: 50%; */
}

.colophon-open-button {
  width: 100%;
  height: 100%;
}

.open-colophon {
  position: absolute;
  opacity: 0.8;
  user-select: none;
}
