.share {
  width: 100%;
  height: 100%;
  position: absolute;
  border: 0;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.share:focus {
  outline: none;
}

.share-container {
  width: 100%;
  height: 100%;
  transition: transform 500ms ease-out;
  transition-delay: 100ms;
  transform: translateY(100%);
}

.share-onscreen {
  transform: translateY(0%);
}

.share-text > div {
  text-decoration: none;
  color: inherit;
  pointer-events: all;
  cursor: pointer;
  text-decoration: none;
  box-shadow: 0 0.12em 0 -0.06em black;
  padding-bottom: 0.12em;
  display: inline;
  user-select: none;
  outline: none;
}

.share-text > div:hover {
  color: grey;
  box-shadow: 0 0.12em 0 -0.06em grey;
}
.share-text > div:active {
  color: white;
  box-shadow: 0 0.12em 0 -0.06em white;
}

.share-close-button-icon {
  width: 100%;
  height: 100%;
}

.share-text {
  padding-left: 30px;
  padding-right: 30px;
  font-size: 4.275vh;
  line-height: 7vh;
  color: black;
  text-align: center;
  transform: translateY(-15%);
}

/* Desktop */
@media (min-width: 701px) {
  .share-close-button {
    position: absolute;
    box-sizing: border-box;
    right: 1em;
    top: 1em;
    width: 2.4em;
    padding: 0.4em;
  }
}

/* Phone */
@media (max-width: 700px) {
  .share-close-button {
    position: absolute;
    box-sizing: border-box;
    right: 1.05em;
    top: 1.05em;
    padding: 0.4em;
  }
}
