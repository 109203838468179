.colophon-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  z-index: 1;
  transition: transform 500ms ease-out;
  transition-delay: 100ms;
}

.colophon-onscreen {
  transform: translateY(0%);
}

.colophon-offscreen {
  transform: translateY(100%);
}

.colophon-canvas {
  position: fixed;
  height: 100vh;
  width: 100%;
  overflow: hidden !important;
  z-index: 0;
  transition: transform 500ms ease-out;
  transition-delay: 100ms;
}
.colophon {
  position: absolute;
  width: 100%;
  max-width: 770px;
  box-sizing: border-box;
  border: 0;
  margin: 0;
  padding: 0;
}

/* Desktop */
@media (min-width: 701px) {
  .colophon {
    padding-left: 36px;
    padding-right: 62px;
    padding-top: 91px;
    padding-bottom: 91px;
  }

  .colophon-text {
    font-size: 34px;
    letter-spacing: 0.004em;
    line-height: 1.42em;
    color: black;
  }
  .line-space1 {
    height: 0.51em;
  }

  .line-space3 {
    height: 0.43em;
  }

  .logo {
    padding-top: 1.85em;
  }

  .logo > img {
    filter: invert();
    color: black;
    height: 3em;
  }

  .line-space4 {
    height: 1.25em;
  }

  .colophon-close-button {
    position: absolute;
    box-sizing: border-box;
    right: 0;
    top: 1em;
    width: 2.4em;
    padding: 0.4em;
  }
}

/* Phone */
@media (max-width: 700px) {
  .colophon {
    padding-left: 23px;
    padding-right: 20px;
    padding-top: 62px;
    padding-bottom: 62px;
  }

  .colophon-text {
    font-size: 23px;
    letter-spacing: 0.023em;
    line-height: 1.48em;
    color: black;
  }
  .line-space1 {
    height: 0.63em;
  }

  .line-space3 {
    height: 0.53em;
  }

  .logo {
    padding-top: 1.8em;
  }

  .logo > img {
    filter: invert();
    color: black;
    height: 3.5em;
  }

  .line-space4 {
    height: 1.1em;
  }

  .colophon-close-button {
    position: absolute;
    box-sizing: border-box;
    right: 1.05em;
    top: 1.05em;
    padding: 0.4em;
  }
}

.colophon-container:focus {
  outline: none;
}

.colophon:focus {
  outline: none;
}

.colophon-close-button-icon {
  width: 100%;
  height: 100%;
}

.line-space2 {
  height: 1.53em;
}

a {
  text-decoration: none;
  color: inherit;
  pointer-events: all;
  text-decoration: none;
  box-shadow: 0 0.12em 0 -0.06em black;
  padding-bottom: 3px;
}
