.intro {
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.7) 100%);
  user-select: none;
}

.intro:focus {
  outline: none;
}

/* Mobile */
@media (max-width: 700px) {
  .intro-text {
    font-size: min(14.7vw, 10vh);
    left: min(7.5vw, calc(7.5vh / 1.47));
    top: min(21vh, calc(1.47 * 21vw));
    transform: translateY(-50%);
    letter-spacing: 0.005em;
    right: min(7.5vw, calc(7.5vh / 1.47));
  }

  .footer {
    width: 83vw;
    font-size: min(4.9vw, 3.35vh);
    padding-left: min(7.5vw, calc(7.5vh / 1.47));
    padding-right: min(7.5vw, calc(7.5vh / 1.47));
    bottom: 5.4vh;
    letter-spacing: 0em;
  }

  .apple-logo {
    width: 6.4vh;
    padding-bottom: 4.25vh;
  }
}

/* Desktop */
@media (min-width: 701px) {
  .intro-text {
    font-size: 11vh;
    left: 10vw;
    top: 10vh;
    letter-spacing: 0.023em;
  }

  .footer {
    font-size: 2.55vh;
    bottom: 4.7vh;
    letter-spacing: 0.023em;
  }

  .apple-logo {
    width: 5.5vh;
    padding-bottom: 3.1vh;
  }
}

.intro-text {
  position: absolute;
  color: white;
  opacity: 0.8;
  user-select: none;
}

.footer {
  position: absolute;
  color: white;
  opacity: 0.8;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  user-select: none;
}
