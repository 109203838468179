.text-area {
  position: absolute;
  border: none;
  padding: 0;
  background: transparent;
  font-family: 'larsregular';
  resize: none;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  outline: none;
}
