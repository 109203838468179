.Logos {
  position: absolute;
  display: block;
  z-index: 99;
  left: 0;
  top: 0;
  width: auto;
  height: auto;
  margin: 0 10px 0 0;
}
.Logos a {
  box-shadow: none;
}
.Logos img {
  width: 50px;
  height: 50px;
  margin: 5px;
}
