html,
body,
#root {
  overscroll-behavior: none;
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  overflow: hidden;
}
