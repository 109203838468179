.Background {
  height: 100%;
  width: 100%;
  position: absolute;
  background-size: 100% 100%;
  transition: opacity 5000ms;
  transition-timing-function: linear;
}

.background-gradient {
  height: 100%;
  width: 100%;
  position: absolute;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 30%);
}
